<template>
  <v-card @drop.prevent="onDrop($event)" @dragover.prevent="dragover = true" @dragenter.prevent="dragover = true" @dragleave.prevent="dragover = false" :class="{ 'grey lighten-2': dragover }">
    <v-card-text>
      <h3>{{ htmlLabel }}</h3>
      <div v-if="multiple || activeFiles.length === 0" class="mt-3 mb-3" style="cursor:pointer" @click="focusFileRef">
        <v-icon large>fal fa-cloud-upload</v-icon>
        <div>Drop your file{{ multiple ? '(s)' : '' }} here to upload, or click to select {{ multiple ? 'one or more files' : 'a file' }}</div>
        <v-file-input ref="fileRef" v-model="fileSelect" :accept="accept" :multiple="multiple" label="File Select" style="opacity:0;position: absolute"></v-file-input>
      </div>
      <v-list v-if="files.length > 0">
        <v-list-item v-for="({ name, active, uploadProgress }, index) in files" :key="index">
          <v-list-item-content>
            <v-list-item-title :style="!active ? 'text-decoration:line-through' : ''">{{ name }}</v-list-item-title>
            <v-list-item-subtitle v-if="uploadProgress === 100">{{ active ? 'Uploaded' : 'Removed' }}</v-list-item-subtitle>
            <v-progress-linear v-else :value="uploadProgress"></v-progress-linear>
          </v-list-item-content>
          <v-list-item-action v-if="multiple || active || activeFiles.length === 0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="toggleFileActive(index)">
                  <v-icon :color="active ? 'error' : 'info'">fal fa-trash{{ active ? '' : '-undo' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ active ? 'Remove' : 'Restore' }} File</span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<style>
  div.v-input.v-input--radio-group.v-input--radio-group--column legend {
    display: block;
    min-height: 30px;
  }
</style>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { uploadFile } from '@/helpers/functions'

export default {
  props: {
    value: {
      type: [String, Array, Object],
      required: true
    },
    label: String,
    dense: Boolean,
    folder: String,
    accept: Array,
    required: Boolean,
    multiple: Boolean,
    min: Number,
    max: Number
  },
  setup (props, { root, emit }) {
    const asteriskOnRequired = computed(() => root.$store.state.forms.formSettings.asteriskOnRequired)
    const htmlLabel = computed(() => {
      if (props.required && (asteriskOnRequired.value === 'true' || asteriskOnRequired.value === true)) {
        return props.label + '<span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em"></span>'
      }
      return props.label
    })
    const myValue = ref([])

    watch(() => props.value, (val) => {
      if (myValue.length === 0) myValue.value = Array.isArray(val) ? val : [val]
    })

    watch(myValue, (val) => {
      emit('input', val)
    })

    const rules = computed(() => {
      let arr = []
      if (props.required) {
        arr.push(v => !!v || 'Required')
      }
      return arr
    })

    const submissionId = computed(() => root.$store.state.forms.submissionId)
    const isUploading = computed({
      get: () => root.$store.state.forms.isUploading,
      set: (val) => root.$store.commit('forms/setIsUploading', val)
    })
    const dragover = ref(false)
    const files = ref([])
    const activeFiles = computed(() => files.value.filter(({ active }) => active))

    function onDrop (e) {
      emit('save')
      dragover.value = false
      if (!props.multiple && (e.dataTransfer.files.length > 1 || activeFiles.value.length > 0)) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'You may only submit one file for this input' })
      } else {
        let dup = false
        e.dataTransfer.files.forEach(element => {
          if (files.value.filter(({ name }) => name === element.name).length === 0) {
            files.value.push({ file: element, name: element.name, filetype: element.type, id: '', uploadProgress: 0, active: true })
          } else {
            dup = true
          }
        })
        if (dup) {
          alert('You cannot upload multiple files with the same name.')
        }
        doUploads()
      }
    }

    watch(submissionId, () => {
      if (files.value.length > 0) doUploads()
    })

    async function doUploads () {
      isUploading.value = true
      let folder = props.folder
      if (props.subfolder) {
        // Determine
      }
      for (let i = 0; i < files.value.length; i++) {
        if (files.value[i].id === '') {
          const id = await uploadFile({ file: files.value[i].file, filename: files.value[i].name, folder }, false, false, ({ loaded, total }) => files.value.splice(i, 1, { ...files.value[i], uploadProgress: Math.round(loaded / total * 100) }))
          files.value.splice(i, 1, { ...files.value[i], id })
        }
      }
      myValue.value = files.value.map(({ id, name, filetype, active }) => { return { id, name, filetype, active } })
      setTimeout(() => {
        emit('save')
        isUploading.value = false
      }, 100)
    }

    function toggleFileActive (index) {
      files.value.splice(index, 1, { ...files.value[index], active: !files.value[index].active })
    }

    const fileRef = ref(null)
    const fileSelect = ref(null)
    function focusFileRef () {
      fileRef.value.$el.children[1].children[0].children[0].click()
    }

    watch(fileSelect, (val) => {
      if (val == null) return
      if (Array.isArray(val)) {
        let dup = false
        for (const element of val) {
          if (files.value.filter(({ name }) => name === element.name).length === 0) {
            files.value.push({ file: element, name: element.name, filetype: element.type, id: '', uploadProgress: 0, active: true })
          } else {
            dup = true
          }
        }
        if (dup) {
          alert('You cannot upload multiple files with the same name.')
        }
        if (submissionId.value !== '') doUploads()
      } else if (val) {
        if (files.value.filter(({ name }) => name === val.name).length > 0) {
          alert('You cannot upload multiple files with the same name.')
          return
        }
        files.value.push({ file: val, name: val.name, filetype: val.type, id: '', uploadProgress: 0, active: true })
        if (submissionId.value !== '') doUploads()
        emit('save')
        root.$nextTick(() => { fileSelect.value = null })
      }
    })

    return {
      htmlLabel,
      myValue,
      rules,
      submissionId,
      dragover,
      files,
      activeFiles,
      onDrop,
      toggleFileActive,
      fileRef,
      fileSelect,
      focusFileRef
    }
  }
}
</script>
